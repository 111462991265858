@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #202020;
  --secondary: #151515;
  --white: #ffffff;
  --danger: crimson;
  --gray: #c6c6c6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary);
}

.text-color-white {
    color: var(--white);
}

.text-color-danger {
    color: var(--danger);
}

.text-color-gray {
    color: var(--gray);
}

.card {
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding: 12px;
    background: var(--secondary);
    color: var(--white);
    border-radius: 8px;
}

.box-shadow-danger {
    box-shadow: 0 0 6px 0 var(--danger);
}

.border-color-danger {
    border-color: var(--danger);
}

.border-bottom-danger {
    border-bottom: solid 6px var(--danger);
}

.bg-secondary {
    background: var(--secondary);
}

::-webkit-scrollbar {
    width: 8px;
    background-color: var(--gray);
}

::-webkit-scrollbar-thumb {
    background-color: var(--danger);
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--gray);
}

#main-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
}

@media screen and (max-width: 1324px) {
    #main-container {
        flex-direction: column;
    }

    .stickyBox {
        position: inherit !important;
        top: inherit !important;
    }
}
