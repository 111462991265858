.container {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        margin-top: 80px;
        align-items: inherit;
        flex-direction: column;
        align-items: center;
    }

    .avatar {
        position: absolute;
        margin-left: -80px;

        img {
            border: solid 4px var(--danger);
            border-radius: 100%;
        }

        @media screen and (max-width: 768px) {
            margin-left: inherit;
            margin: 0 auto;
            margin-top: -105px;
        }
    }

    .body {
        background: var(--secondary);
        color: var(--white);
        padding: 16px;
        padding-left: 86px;
        border: solid 4px var(--danger);
        border-radius: 8px;

        @media screen and (max-width: 768px) {
            padding-top: 30px;
            padding-left: 16px;
        }

        @media screen and (max-width: 650px) {
            padding-top: 50px;
        }
    }
}